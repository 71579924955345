import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { BRANDS_LIST } from "../../constants";

const checkUnique = (values: any[] | undefined, property: string) => {
  const uniqueNames = new Set(values?.map((obj) => obj[property]));
  if (uniqueNames.size !== values?.length) {
    return false;
  }
  return true;
};

const validateTotal = (values: any[] | undefined) => {
  const sum = values?.reduce((acc, curr) => acc + curr.share, 0);
  return sum === 100 || sum === 0;
};

export const CreateCompanySchema = yup.object({
  name: yup.string().required(),
  businessSegments: yup.array().min(1).of(yup.string()).required(),
  town: yup.string().required(),
  street: yup.string().required(),
  useBoschFullService: yup.string().required(),
  numberOfEmployees: yup.string().required(),
  contacts: yup.array().of(
    yup.object({
      name: yup.string().required(),
      position: yup.string().required(),
      phoneNumber: yup
        .string()
        .required()
        .test(
          "phoneNumber",
          "Invalid phone number: Start with country code: E.g: +254 followed by phone number",
          (value) => {
            return isValidPhoneNumber(value);
          }
        ),
      email: yup.string().optional(),
    })
  ),
  numberOfMeasuringTools: yup.number().min(0).max(1000).required(),
  numberOfPowerTools: yup.string().required(),
  serviceProviderType: yup.array().min(1).of(yup.string()).required(),
  powerToolBrandsUsed: yup
    .array()
    .min(1)
    .of(
      yup.object({
        company: yup.string().required(),
        share: yup.number().min(0).max(100),
      })
    )
    .test("sum-of-percentages", "sum of percentages must be 100", (values) =>
      validateTotal(values)
    )
    .test("company-unique-values", "companies must be unique", (values) =>
      checkUnique(values, "company")
    ),
  accessoryBrandsUsed: yup
    .array()
    .min(1)
    .of(
      yup.object({
        company: yup.string().required(),
        share: yup.number().min(0).max(100),
      })
    )
    .test("sum-of-percentages", "sum of percentages must be 100", (values) =>
      validateTotal(values)
    )
    .test("company-unique-values", "companies must be unique", (values) =>
      checkUnique(values, "company")
    ),
  measuringToolBrandsUsed: yup
    .array()
    .min(1)
    .of(
      yup.object({
        company: yup.string().required(),
        share: yup.number().min(0).max(100),
      })
    )
    .test("sum-of-percentages", "sum of percentages must be 100", (values) =>
      validateTotal(values)
    )
    .test("company-unique-values", "companies must be unique", (values) =>
      checkUnique(values, "company")
    ),
  dealerPurchaseFrom: yup.array(yup.string().required()).min(1).optional(),
  // TODO: Potential point of entry failure
  servicePartners: yup.array().of(
    yup.object({
      name: yup.string().optional(),
    })
  ),
});

export type CreateCompanyFormData = yup.InferType<typeof CreateCompanySchema>;

type PartialCompanyFormData = Partial<CreateCompanyFormData>;
export class CompanyDataForm implements CreateCompanyFormData {
  contacts?:
    | {
        email?: string | undefined;
        name: string;
        position: string;
        phoneNumber: string;
      }[]
    | undefined;
  powerToolBrandsUsed?: { company: string; share?: number }[] | undefined;
  accessoryBrandsUsed?: { company: string; share?: number }[] | undefined;
  measuringToolBrandsUsed?: { company: string; share?: number }[] | undefined;
  dealerPurchaseFrom?: string[] | undefined;
  servicePartners?: { name?: string | undefined }[] | undefined;
  name: string;
  businessSegments: (string | undefined)[];
  town: string;
  street: string;
  useBoschFullService: string;
  numberOfEmployees: string;
  numberOfMeasuringTools: number;
  numberOfPowerTools: string;
  serviceProviderType: (string | undefined)[];

  constructor(data: PartialCompanyFormData) {
    // Initialize properties based on constructor arguments
    this.contacts =
      data.contacts?.length === 0 || !data?.contacts
        ? [{ name: "", position: "", phoneNumber: "", email: "" }]
        : data.contacts;
    this.powerToolBrandsUsed =
      data.powerToolBrandsUsed?.length === 0 || !data?.powerToolBrandsUsed
        ? [{ company: BRANDS_LIST[0], share: undefined }]
        : data.powerToolBrandsUsed;
    this.accessoryBrandsUsed =
      data.accessoryBrandsUsed?.length === 0 || !data?.accessoryBrandsUsed
        ? [{ company: BRANDS_LIST[0], share: undefined }]
        : data.accessoryBrandsUsed;
    this.measuringToolBrandsUsed =
      data.measuringToolBrandsUsed?.length === 0 ||
      !data?.measuringToolBrandsUsed
        ? [{ company: BRANDS_LIST[0], share: undefined }]
        : data.measuringToolBrandsUsed;
    this.dealerPurchaseFrom = data?.dealerPurchaseFrom || [];
    this.servicePartners = data?.servicePartners || [{ name: "" }];
    this.name = data?.name || "";
    this.businessSegments = data?.businessSegments || [];
    this.town = data?.town || "";
    this.street = data?.street || "";
    this.useBoschFullService = data?.useBoschFullService || "";
    this.numberOfEmployees = data?.numberOfEmployees || "";
    this.numberOfMeasuringTools = data?.numberOfMeasuringTools || 0;
    this.numberOfPowerTools = data?.numberOfPowerTools || "";
    this.serviceProviderType = data?.serviceProviderType || [];
  }
}

export type CreatePartialCompanyDto = {
  name: string;
};
