import { useMutation } from "@tanstack/react-query";
import { useAxiosInstance } from "../hooks/useAxiosInstance";
import { SignUpFormData } from "../routes/auth/schema/signup.schema";
import { GetAppUsersDto } from "./dtos/appUsers.dto";

export function useAppUserAPI() {
  const axiosInstance = useAxiosInstance();

  const fetchAppUserInfo = async (subjectID: string) => {
    const { data } = await axiosInstance.get<GetAppUsersDto>(
      `app-users/${subjectID}`
    );
    return data;
  };

  const useCreateAppUserMutation = () => {
    return useMutation({
      mutationFn: (appUserDto: SignUpFormData) => {
        return axiosInstance.post("app-users", appUserDto);
      },
    });
  };
  return { fetchAppUserInfo, useCreateAppUserMutation };
}
