import React, { useContext, useEffect, useState } from "react";
import { SearchInput } from "../../components/form/SearchInput";
import { Button, Form, Modal } from "react-bootstrap";
import { FormSelect } from "../../components/form/FormSelect";
import { Wrapper } from "@googlemaps/react-wrapper";
import MapContainer from "./MapContainer";
import { Status } from "@googlemaps/react-wrapper";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import MapProjectsList from "./MapProjectsList";
import { GetProjectsDto } from "../../services/dtos/projects.dto";
import { useProjectAPI } from "../../services/projects.service";
import { PROJECT_TYPES, PROJECT_VALUE_ESTIMATES } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { MapFilterSchema } from "./map-filter.schema";
import { City, cities } from "../../constants/index";

import {
  multiFilterListOfBooleansByKey,
  multiFilterListOfObjectsByKey,
  multiFilterListOfStringsByKey,
} from "../../constants/utils";
import UserService from "../../services/user.service";

export function MapScreen() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);
  // const { useFilterProjects } = useProjectAPI();

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Projects Map",
      });
    }
  }, [pageTitleDispatch]);

  // const [queryValue, setQueryValue] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [estimatedValue, setEstimatedValue] = useState<string>("");
  const [blankCount, setBlankCount] = useState<number>(0);

  useEffect(() => {
    const countBlanks = () => {
      let count = 0;
      if (type.trim() !== "") {
        count++;
      }
      if (estimatedValue.trim() !== "") {
        count++;
      }
      setBlankCount(count);
    };

    countBlanks();
  }, [type, estimatedValue]);

  const handleSearchTextChange = (value: string) => {
    const searchKey: keyof GetProjectsDto = "name";

    if (value.length < 1) {
      setProjectList(data || []);
    } else {
      const searchedList = multiFilterListOfStringsByKey(
        projectList,
        value || "",
        searchKey
      );

      setProjectList(searchedList);
    }
  };

  const initFilterProjectData = {
    type: "",
    estimatedValue: "",
  };

  const { handleSubmit, control } = useForm<MapProjectFilters>({
    resolver: yupResolver(MapFilterSchema),
    defaultValues: initFilterProjectData,
    mode: "onChange",
  });

  //Fetching country information -----------------------------------------------
  const country: string = UserService.getUserCountry();

  const [coords, setCoords] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 1, lng: 20 });
  const x = getCoords(country);
  useEffect(() => {
    setCoords({ lat: x?.lat || 1, lng: x?.lng || 20 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  const [zoom, setZoom] = useState<number>(10);
  function getCoords(country: string): City | undefined {
    switch (country) {
      case "Kenya":
        return cities.find((country: City) => country.name === "Kenya");
      case "Egypt":
        return cities.find((country: City) => country.name === "Egypt");
      case "South Africa":
        return cities.find((country: City) => country.name === "South Africa");
      case "Nigeria":
        return cities.find((country: City) => country.name === "Nigeria");
      case "Ghana":
        return cities.find((country: City) => country.name === "Ghana");
      case "Angola":
        return cities.find((country: City) => country.name === "Angola");
      case "Tunisia":
        return cities.find((country: City) => country.name === "Tunisia");
      case "Morocco":
        return cities.find((country: City) => country.name === "Morocco");
      default:
        cities.find((country: City) => country.name === "Morocco");
    }
  }
  //End of fetching country information -----------------------------------------------

  // const {
  //   isLoading,
  //   error,
  //   data: filterData,
  // } = useFilterProjects(queryValue, estimatedValue);
  const { useFetchProjects } = useProjectAPI();
  const { status, data } = useFetchProjects();
  useEffect(() => {
    // Filter projects
    setProjectList(data || []);
  }, [data]);

  const [projectList, setProjectList] = useState<GetProjectsDto[]>([]);

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <div
            style={{
              height: "40Vh",
              background: "#f5f5f5",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <h6>
              <b>Loading...</b>
            </h6>
          </div>
        );
      case Status.SUCCESS:
        return (
          <MapContainer
            center={{
              lat: coords.lat,
              lng: coords.lng,
            }}
            zoom={zoom}
            mapListItems={projectList}
          />
        );
      case Status.FAILURE:
        return (
          <div
            style={{
              height: "40Vh",
              background: "#f5f5f5",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <h6>
              <b>Failed!</b>
            </h6>
          </div>
        );
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  type MapProjectFilters = {
    type: string;
    estimatedValue: string;
    isCompleted: boolean;
  };

  const [isChecked, setIsChecked] = React.useState(true);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  // Filter button clicked
  const onSubmit: SubmitHandler<MapProjectFilters> = (filter) => {
    const filteredProjectList = multiFilterListOfObjectsByKey<GetProjectsDto>(
      projectList || [],
      [filter.type],
      "type"
    );

    console.log("type is " + filter.type);
    console.log("1. default list is " + data);
    console.log("2. type list is " + filteredProjectList);
    const list = filter.type.length < 1 ? data : filteredProjectList;
    const filteredProjectListByActiveProjects = multiFilterListOfBooleansByKey(
      list || [],
      [!isChecked],
      "isCompleted"
    );
    console.log(
      "3. after completed list is " + filteredProjectListByActiveProjects
    );

    const filteredProjectListByEstimatedValue =
      multiFilterListOfObjectsByKey<GetProjectsDto>(
        filteredProjectListByActiveProjects || [],
        [filter.estimatedValue],
        "estimatedValue"
      );
    console.log(
      "4. after estimated list is " + filteredProjectListByEstimatedValue
    );

    const list4 =
      filter.estimatedValue.length < 1
        ? filteredProjectListByActiveProjects
        : filteredProjectListByEstimatedValue;

    setType(filter.type);
    // setIsChecked(filter.isCompleted);
    setEstimatedValue(filter.estimatedValue);
    setProjectList(list4);
  };

  return (
    <React.Fragment>
      <div className="align-items-center">
        {/* Filter screen */}
        <div
          className="row align-items-center"
          style={{
            position: "sticky",
            top: "0",
            paddingRight: "20",
          }}
        >
          <Modal show={showModal} onHide={closeModal} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)} className="m-2">
                <FormSelect
                  name={"type"}
                  label={"Filter by Type"}
                  control={control}
                  options={PROJECT_TYPES}
                />

                <FormSelect
                  name={"estimatedValue"}
                  label={"Filter by Estimated Value"}
                  control={control}
                  options={PROJECT_VALUE_ESTIMATES}
                />
                <Form.Check
                  type="checkbox"
                  id="checkbox-example"
                  label="All projects (including completed)"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <br />
                <Button
                  className="rounded-pill"
                  size="sm"
                  type="submit"
                  color="primary"
                  active={true}
                  onClick={() => {
                    handleSubmit(onSubmit);
                    closeModal();
                  }}
                >
                  Apply
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <div
            className="col-8"
            style={{ position: "sticky", top: "0", marginLeft: "0px" }}
          >
            <SearchInput
              placeHolderText="Search sites"
              onTextChange={handleSearchTextChange}
            />
          </div>
          {/* Filter button */}
          <div className="col-3">
            <Button
              className="rounded-pill"
              style={{
                backgroundColor: "primary",
                borderColor: "primary",
                marginRight: 10,
              }}
              active={true}
              size="sm"
              variant="tertiary"
              onClick={() => {
                openModal();
              }}
            >
              Filter{blankCount > 0 ? "s(" + blankCount + ")" : ""}
            </Button>
          </div>

          {/* Map  */}
          <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
            render={render}
            region={country === "Morocco" ? "MA" : ""}
          ></Wrapper>
        </div>

        {/* Map list items below */}
        <h5 style={{ paddingTop: "10px", color: "grey" }}>Sites nearby</h5>

        <div style={{ paddingTop: 0 }}>
          <MapProjectsList
            onHandleItemClick={(item: GetProjectsDto) => {
              setZoom(17);
              setCoords({ lat: item.latitude, lng: item.longitude });
            }}
            status={status}
            filteredItems={[...projectList]}
          />
          <br />
          <p style={{ textAlign: "center", color: "grey" }}>• - - •</p>
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}

// const Cities = [
//   {
//     name: "Kenya",
//     lat: -1.286389,
//     lng: 36.817223,
//   },
//   {
//     name: "Nigeria",
//     lat: 9.05785,
//     lng: 7.49508,
//   },
//   {
//     name: "Morocco",
//     lat: 33.9715904,
//     lng: 6.8498125,
//   },
//   {
//     name: "Ghana",
//     lat: 5.560014,
//     lng: -0.205744,
//   },
//   {
//     name: "Tunisia",
//     lat: 36.806495,
//     lng: 10.181532,
//   },
//   {
//     name: "South Africa",
//     lat: -25.746111,
//     lng: 28.188056,
//   },
//   {
//     name: "Angola",
//     lat: -8.838333,
//     lng: 13.234444,
//   },
//   {
//     name: "Egypt",
//     lat: 30.0444,
//     lng: 31.2357,
//   },
// ];
