export const PROJECT_STAGES = [
  "Surveying",
  "Excavation",
  "Structural",
  "Plumbing",
  "Partitioning",
  "Electrical",
  "Flooring / Ceiling",
  "Interior",
  "Roofing",
  "Landscaping / Outside",
];

export const CHART_CONFIG = {
  height: 400,
};

export const CONTRACTOR_SEGMENTS = [
  "Architect",
  "Bricklaying / plastering",
  "Civil engineering / excavating",
  "Concrete / formwork",
  "Electrician",
  "Elevator mechanic",
  "Fencing",
  "Fire systems",
  "Floor installation",
  "Glazier / Glass / window / door",
  "HVAC (heat, ventil., air cond.)",
  "Interior / carpentry / kitchen",
  "Landscaping",
  "Painting",
  "Plumbing / pipefitting",
  "Roofer",
  "Scaffolding",
  "Security",
  "Welding",
  "Mining",
  "Manufacturing",
];

export const PROJECT_TYPES = [
  "Airport",
  "Port",
  "Railway",
  "Bridge",
  "Road",
  "Hotel",
  "Casino",
  "Shopping Mall",
  "Energy",
  "Warehouse",
  "Commercial",
  "Residential",
];

export const PROJECT_VALUE_ESTIMATES = [
  "<0.5 m EUR",
  "0.5 - 1.5 m EUR",
  "1.5 - 3.0 m EUR",
  "3.0 - 5.0 m EUR",
  "5.0 - 10 m EUR",
  "10 - 25 m EUR",
  "25 - 100 m EUR",
  "100 - 500 m EUR",
  "more than 500 m EUR",
];

export interface Company {
  name: string;
  id: string;
}

export enum ProjectStatus {
  PENDING = "PENDING",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  NO_INFO = "N/A",
}
export interface ProjectStage {
  name: string;
  status: ProjectStatus;
  companies: Company[];
}
export const BASE_API_URL = process.env.REACT_APP_API_URL || "no_url_found";
export const PT_API_URL = BASE_API_URL + "/pt/";

export const BOSCH_COLORS = {
  gray: "#71767C",
  red: "#ED0007",
  purple: "#9E2896",
  blue: "#005691",
  turquoise: "#18837E",
  green: "#00884A",
  yellow: "#FFCF00",
};

export const USERNAME = process.env.REACT_APP_USER;
export const PASSWORD = process.env.REACT_APP_USER_PASS;

export const BRANDS_LIST = [
  "Bosch",
  "Makita",
  "Powerflex",
  "Maxmech",
  "Milwaukee",
  "Jeehoo",
  "APT",
  "Dewalt",
  "DCA(Dongcheng)",
  "Power master",
  "LIDA",
  "IDEAL",
  "Ingo",
  "Einhell",
  "Maktec",
  "Metabo",
  "KPT",
  "AEG",
  "Hitachi / Hikoki",
  "Crown",
  "Ryobi",
  "CMS",
  "Black &amp; Decker",
  "Powerplus",
  "Powertech",
  "Raider",
  "Fugita",
  "Stanley",
  "Hilti",
  "Makute",
  "Siemens",
  "Ingco",
  "Total",
  "Boda",
  "Bati",
  "Acem",
  "Wido",
  "Stainly",
  "SBD",
  "Fit",
  "MPT",
  "FEIN",
];

export const cities: City[] = [
  {
    name: "Kenya",
    lat: -1.286389,
    lng: 36.817223,
  },
  {
    name: "Nigeria",
    lat: 9.05785,
    lng: 7.49508,
  },
  {
    name: "Morocco",
    lat: 33.9715904,
    lng: -6.8498125,
  },
  {
    name: "Ghana",
    lat: 5.560014,
    lng: -0.205744,
  },
  {
    name: "Tunisia",
    lat: 36.806495,
    lng: 10.181532,
  },
  {
    name: "South Africa",
    lat: -25.746111,
    lng: 28.188056,
  },
  {
    name: "Angola",
    lat: -8.838333,
    lng: 13.234444,
  },
  {
    name: "Egypt",
    lat: 30.033,
    lng: 31.233,
  },
];

export interface City {
  name: string;
  lat: number;
  lng: number;
}

export const x = "jim";
